import { Accordion, AccordionItemProps } from '@almbrand/accordion'; // Adjust the import path as necessary
import React from 'react';

interface AccordionComponentProps extends LocalId {
	isFaqAccordions: boolean;
	header: string;
	accordionsArea: {
		groups: {
			title: string;
			accordionItems: {
				version: number;
				items: {
					content: {
						question: string;
						answer: string;
						isNew: boolean;
						showLastUpdated: boolean;
						lastUpdatedText: string;
						contentType: string;
						contentId: number;
					};
				}[];
			};
			contentType: string;
			contentId: number;
		}[];
	};
	ctaBlock: {
		btnType: string;
		btnSize: string;
		btnColor: string;
		link: {
			useVueRoute: boolean;
		};
		target: string;
	};
	style: string;
	padding: boolean;
	backgroundColor: {
		cssClass: string;
		colorHex: string;
		colorComplexion: string;
	};
	itemsBackgroundColor: {
		cssClass: string;
		colorHex: string;
		colorComplexion: string;
	};
	showUsabillaInPage: boolean;
	useReadline: boolean;
	contentId?: string | number;
}

export const AccordionComponent: React.FC<AccordionComponentProps> = ({
	isFaqAccordions,
	header,
	accordionsArea,
	ctaBlock,
	style,
	padding,
	backgroundColor,
	itemsBackgroundColor,
	showUsabillaInPage,
	useReadline,
	readlineId,
	contentId,
}) => {
	const mapAccordionItems = (items: { content: { question: string; answer: string } }[]): AccordionItemProps[] =>
		items.map((item, index) => ({
			id: index,
			title: item.content.question,
			content: item.content.answer,
			isActive: false,
		}));

	// Flatten the accordion groups into a single array of AccordionItemProps
	const allAccordionItems = accordionsArea.groups.flatMap((group) => mapAccordionItems(group.accordionItems.items));

	return (
		<Accordion id={useReadline && readlineId ? readlineId : contentId} header={header} items={allAccordionItems} />
	);
};
