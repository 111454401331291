/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import './Chatbot.scss';

export interface ChatbotProps {
	chatbotLabel: string;
}

export const Chatbot: React.FC<ChatbotProps> = ({ chatbotLabel = 'Spørg mig' }) => {
	const dataLayer = (window as any).dataLayer || [];

	// getting conversation id and chatbot state from session storage
	let _conversationId = sessionStorage.getItem('conversationId');
	const ChatBotState = sessionStorage.getItem('ChatBotState');

	let autoPoppedUp = false;
	let autoPopUpChatDelay: any;
	let autoClosePopUpChatDelay: any;

	const chatPanel = (window as any).boostChatPanel({
		apiUrlBase: 'https://codanekstern.boost.ai/api',
		conversationId: _conversationId,
	});

	// Open chatbot
	function openChatBot() {
		chatPanel.show();
		try {
			ChatBotState == 'CLOSED' && clearTimeout(autoPopUpChatDelay);
		} catch (error) {
			console.log('error');
		}
		sessionStorage.getItem('conversationId');
		sessionStorage.setItem('ChatBotState', 'OPEN');

		dataLayer.push({
			event: 'trackChat',
			chatAction: autoPoppedUp ? 'chat auto-popup' : 'chat start',
		});
	}

	//TODO: Check if more tracking is needed for auto minimize
	if (window.innerWidth > 767 && ChatBotState !== 'CLOSED') {
		autoPopUpChatDelay = setTimeout(function () {
			sessionStorage.setItem('ChatBotState', 'OPEN');
			autoPoppedUp = true;
			openChatBot();
			// closes in case there was no response from user
			autoClosePopUpChatDelay = setTimeout(function () {
				chatPanel.minimize();
			}, 30000);
		}, 30000);
	}

	if (_conversationId && ChatBotState !== 'CLOSED') {
		openChatBot();
	}

	chatPanel.addEventListener('conversationIdChanged', function (event: any) {
		const conversationId = event.detail.conversationId;
		_conversationId = conversationId;
		sessionStorage.setItem('conversationId', conversationId);
	});

	// Listen for messageSent and clears timeout for automatically minimizing chat
	chatPanel.addEventListener('messageSent', function () {
		try {
			clearTimeout(autoClosePopUpChatDelay);
			sessionStorage.setItem('ChatBotState', 'OPEN');
			dataLayer.push({
				event: 'trackChat',
				chatAction: 'chat message send',
			});
		} catch (error) {
			console.log('error');
		}
	});

	chatPanel.addEventListener('chatPanelMinimized', function () {
		sessionStorage.setItem('ChatBotState', 'CLOSED');
		dataLayer.push({
			event: 'trackChat',
			chatAction: autoPoppedUp ? 'chat minimize (auto-popup)' : 'chat minimize',
		});
	});

	chatPanel.addEventListener('chatPanelClosed', function () {
		sessionStorage.setItem('ChatBotState', 'CLOSED');
		autoPoppedUp = false;

		dataLayer.push({
			event: 'trackChat',
			chatAction: autoPoppedUp ? 'chat close (auto-popup)' : 'chat close',
		});
	});

	// Listen for conversationDownloaded
	chatPanel.addEventListener('conversationDownloaded', function () {
		dataLayer.push({
			event: 'trackChat',
			chatAction: 'chat download conversation',
		});
	});

	// Listen for conversationDeleted
	chatPanel.addEventListener('conversationDeleted', function () {
		dataLayer.push({
			event: 'trackChat',
			chatAction: 'chat delete conversation',
		});
	});

	// Listen for positiveFeedback
	chatPanel.addEventListener('positiveConversationFeedbackGiven', function () {
		dataLayer.push({
			event: 'trackChatRating',
			chatAction: 'thumbs up',
		});
	});

	// Listen for negativeFeedback
	chatPanel.addEventListener('negativeConversationFeedbackGiven', function () {
		dataLayer.push({
			event: 'trackChatRating',
			chatAction: 'thumbs down',
		});
	});

	// Open chatbot by sending a message, functions need to be called on e.g Chatbot tile
	//  This is probably not needed in the Chatbot component but on a tile for example
	// const openWithMessage = (
	// 	openChatWithMessage: boolean,
	// 	chatbotSendMessage: boolean,
	// 	message: string | undefined,
	// ) => {
	// 	openChatWithMessage && openChatBot();
	// 	chatbotSendMessage && message && boost.chatPanel.sendMessage(message);
	// };

	return (
		<button type="button" className="Chatbot__triggerButton" onClick={openChatBot}>
			{chatbotLabel}
			{/* <SystemIcons themeName={'Codan-theme'} icon={'boat'} height={48} width={48} /> */}
			<p>🤖</p>
		</button>
	);
};
