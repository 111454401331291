import { RenderContent } from 'components/Component';
import { PageContext } from 'context/PageContext';
import React, { useContext } from 'react';

import { Container } from '@almbrand/container';
import { Grid, GridCell } from '@almbrand/grid';
import { NotificationBanner } from 'components/2-molecules';
import { Chatbot } from 'components/2-molecules/Chatbot';
import { FooterComponent, HeaderComponent } from 'components/4-habitats';

export const ContentPage: React.FC<PageModel> = () => {
	const pageContext = useContext(PageContext);

	return (
		<>
			<Container desktopWidth="fullWidth" tabletWidth="fullWidth" mobileWidth="fullWidth">
				<Container desktopWidth="1600" tabletWidth="1280" mobileWidth="744">
					<Grid>
						<GridCell>
							<HeaderComponent {...pageContext?.headerContent} />
						</GridCell>
					</Grid>
				</Container>
			</Container>

			{pageContext?.notificationBannerContent && (
				<Container desktopWidth="1600" tabletWidth="1280" mobileWidth="744">
					<Grid container>
						<GridCell>
							<NotificationBanner {...pageContext?.notificationBannerContent} />
						</GridCell>
					</Grid>
				</Container>
			)}

			{pageContext.content && pageContext.content.items && <RenderContent items={pageContext.content.items} />}

			<Container noPadding desktopWidth="fullWidth" tabletWidth="fullWidth" mobileWidth="fullWidth">
				<FooterComponent {...pageContext?.footerContent} />
				<Chatbot chatbotLabel={'Spørg mig'} />
			</Container>
		</>
	);
};
