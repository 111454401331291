import { Divider, DividerProps } from '@almbrand/divider';

export interface DividerComponentProps extends LocalId {
	title: string;
	dividerColor: DividerProps['dividerType'];
	contentId: number;
	// backgroundColor?: BackgroundColor; // TODO: is it needed?
}

export const DividerComponent: React.FC<DividerComponentProps> = ({
	title,
	dividerColor = 'primary',
	contentId,
	useReadline,
	readlineId,
}) => {
	return <Divider id={useReadline && readlineId ? readlineId : contentId} title={title} dividerType={dividerColor} />;
};
