const dict: Map<string, string> = new Map();

/**
 * map to Map() with key to lowercase and trimmed
 * @param dictionary : KeyValue[]
 * @returns Map() of key values or empty Map()
 */
export const mapDictionary = (dictionary: KeyValue[]): Map<string, string> => {
	try {
		if (dictionary) {
			dictionary.map((entry) => {
				const cleanKey = entry?.key?.toLocaleLowerCase().trim();
				cleanKey ? dict.set(cleanKey, entry.value) : undefined;
			});
		}
		return dict;
	} catch (e) {
		console.error(e);
	}
};
/**
 *
 * @param key
 * @param fallback value hardcoded
 * @returns value from map or fallback or undefined
 */
export const dictionaryValue = (key: string, fallback?: string): string => {
	try {
		let val: string = undefined;
		key ? (val = dict.get(key.toLocaleLowerCase().trim())) : undefined;
		return val || fallback || undefined;
	} catch (e) {
		console.error(e);
	}
};
