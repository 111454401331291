import { LocalNavigation as LocalNavigationComponent } from '@almbrand/localnavigation';

interface LocalId {
	useReadline?: boolean;
	readlineId?: string;
	readlineTitle?: string;
}

export interface LocalNavigationProps {
	contentId?: string | number;
	readlines?: LocalId[];
}

export const LocalNavigation: React.FC<LocalNavigationProps> = ({ readlines, contentId }) => {
	return (
		<LocalNavigationComponent
			id={contentId}
			navigationList={readlines?.map((link) => ({
				title: link.readlineTitle,
				id: link.readlineId,
			}))}
		/>
	);
};
