import { Container } from '@almbrand/container';
import { Grid, GridCell } from '@almbrand/grid';
import { DividerComponent, HeadlineComponent, RichTextComponent } from './1-atoms';
import { MediaComponent } from './1-atoms/Media/MediaComponent';
import { YoutubeComponent } from './1-atoms/YoutubeComponent/YoutubeComponent';
import { IframeComponent, TileComponent } from './2-molecules';
import { HeroComponent, TeaserCard } from './3-organisms';
import { AccordionComponent } from './3-organisms/AccordionComponent/AccordionComponent';
import { LocalNavigation } from './4-habitats';

export const Component: React.FC<ContainerBlock> = (props) => {
	const components = {
		TileBlock: TileComponent,
		ParagraphBlock: RichTextComponent,
		HeadLineBlock: HeadlineComponent,
		DividerBlock: DividerComponent,
		HeroBlock: HeroComponent,
		TeaserCardBlock: TeaserCard,
		BatgirlIFrameBlock: IframeComponent,
		ReadlineBlock: LocalNavigation,
		AccordionContainerBlock: AccordionComponent,
		MediaBlock: MediaComponent,
		YoutubeBlock: YoutubeComponent,
	};

	// Check if the contentType is recognized or should use a default handling
	const DynamicComponent = components[props.contentType];

	if (!DynamicComponent) {
		if (props.contentType === 'DefaultContainerBlock') {
			// Handle the DefaultContainerBlock differently or do nothing
			return null; // Do nothing, or render a default placeholder as needed
		}
		return (
			<div>{`Component [${props.contentType}] is missing a React Component. Check /components/Component.tsx`}</div>
		);
	}

	return <DynamicComponent {...props} />;
};

const getClassName = (style) => {
	const marginTop = style?.marginTop ? `mt-${style.marginTop}` : '';
	const marginBottom = style?.marginBottom ? `mb-${style.marginBottom}` : '';
	return `${marginTop} ${marginBottom}`.trim();
};

export const RenderContent: React.FC<MainArea> = ({ items }) => {
	return items.map((item, index) => {
		const { style, content } = item ?? {};
		const { innerBackgroundColor, gridStyle } = style ?? {};
		const className = getClassName(style);
		if (content.contentType == 'ReadlineBlock') return <Component {...content} key={index} />;
		return (
			<Container
				desktopWidth="fullWidth"
				tabletWidth="fullWidth"
				mobileWidth="fullWidth"
				backgroundColor={innerBackgroundColor?.cssClass}
				key={index}
			>
				<Container desktopWidth="1600" tabletWidth="1280" mobileWidth="744" className={className}>
					<Grid container={gridStyle === 'fullscreen'}>
						{content?.contentType === 'DefaultContainerBlock' ? (
							content?.mainArea?.items?.map((subItem, subIndex) => {
								const subClassName = getClassName(subItem.style);
								return <RenderComponent item={subItem} className={subClassName} key={subIndex} />;
							})
						) : (
							<RenderComponent item={item} className={className} />
						)}
					</Grid>
				</Container>
			</Container>
		);
	});
};

const RenderComponent: React.FC<{ item: ContentApiModel; className: string }> = ({ item, className }) => {
	const { gridSize, tabletGridSize, mobileGridSize, gridMove, mobileGridMove, tabletGridMove } = item?.grid ?? {};

	return (
		<GridCell
			desktopWidth={gridSize}
			tabletWidth={tabletGridSize}
			mobileWidth={mobileGridSize}
			desktopOffset={gridMove}
			tabletOffset={mobileGridMove}
			mobileOffset={tabletGridMove}
			className={className}
		>
			<Component {...item.content} />
		</GridCell>
	);
};
