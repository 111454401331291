import { ButtonProps } from '@almbrand/button';
import { TeaserCard as TeaserComponent, TeaserCardProps as TeaserComponentProps } from '@almbrand/teasercard';

export interface TeaserCardProps extends LocalId {
	backgroundColor?: BackgroundColor;
	contentId: number | string;
	textArea?: string;
	imgHeight: TeaserComponentProps['icon']['size'];
	image: Image;
	ctaBlock?: CTABlock;

	padding?: number | string;
	imgRatio?: string;
	paddingBottom?: number | string;
	paddingSides?: number | string;
	paddingTop?: number | string;
}

export const TeaserCard: React.FC<TeaserCardProps> = ({
	textArea,
	image,
	backgroundColor,
	contentId,
	ctaBlock,
	imgHeight,
	paddingBottom,
	paddingSides,
	paddingTop,
	useReadline,
	readlineId,
}) => {
	const { title, btnType, link } = ctaBlock ?? {};
	const buttonType = (btnType as ButtonProps['buttonType']) || 'primary'; // FIXME: find away to handle button types

	const uspTeaserCard = paddingBottom && paddingTop && paddingSides === '0' ? true : false;

	return (
		<TeaserComponent
			id={useReadline && readlineId ? readlineId : contentId}
			backgroundColor={backgroundColor?.cssClass}
			icon={{
				url: image?.url,
				altText: image?.properties?.altText,
				size: imgHeight,
			}}
			content={textArea}
			buttonProps={{
				children: title,
				buttonType: buttonType,
				linkButtonSettings: {
					href: link?.url,
				},
			}}
			usp={uspTeaserCard}
		/>
	);
};
