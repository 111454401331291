import { RichText } from '@almbrand/richtext';

export interface RichTextComponentProps extends LocalId {
	//	countdownPositionTop: boolean;
	//	header: string;
	richText: string;
	//	contentText: string;
	//	padding: string;
	backgroundColor?: BackgroundColor; // TODO: map in component library
	//	trackAsPromotion: boolean; // TODO: how can we use this one
	//	ctaMarginTop: string;
	//	ctaMarginBottom: string;
	contentId?: number | string;
}

export const RichTextComponent: React.FC<RichTextComponentProps> = ({
	richText,
	contentId,
	useReadline,
	readlineId,
}) => {
	return <RichText id={useReadline && readlineId ? readlineId : contentId} description={richText} />;
};
