import { Iframe } from '@almbrand/iframe';

export interface IframeComponentProps extends LocalId {
	contentId?: number | string;
	height?: string;
	iframeTitle: string;
	iframeUrl?: string;
	width?: string;
}

export const IframeComponent: React.FC<IframeComponentProps> = ({
	contentId,
	height,
	iframeTitle,
	iframeUrl,
	width,
	useReadline,
	readlineId,
}) => {
	return (
		<Iframe
			id={useReadline && readlineId ? readlineId : contentId}
			title={iframeTitle}
			src={iframeUrl}
			width={width}
			height={height}
		/>
	);
};
