import { Headline, HeadlineProps } from '@almbrand/headline';

export interface HeadlineComponentProps extends LocalId {
	title: string;
	size: HeadlineProps['size'];
	heading: HeadlineProps['variant'];
	contentId: number;
}

export const HeadlineComponent: React.FC<HeadlineComponentProps> = ({
	title,
	size,
	heading,
	contentId,
	useReadline,
	readlineId,
}) => {
	return (
		<Headline id={useReadline && readlineId ? readlineId : contentId} size={size} title={title} variant={heading} />
	);
};
