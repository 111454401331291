import { NotificationBanner as NotificationBannerComponent } from '@almbrand/notificationbanner';
import { useState } from 'react';

export const NotificationBanner: React.FC<NotificationReference> = ({
	richText,
	icon,
	backgroundColor,
	closeIcon,
	contentId,
}) => {
	const sessionKey = `close-notification-banner-${contentId}`;

	const [displayBanner, setDisplayBanner] = useState(!sessionStorage.getItem(sessionKey));

	const onBannerClose = () => {
		sessionStorage.setItem(sessionKey, 'true');
		setDisplayBanner(false);
	};

	return (
		displayBanner && (
			<NotificationBannerComponent
				content={richText}
				alertIcon={{ url: icon?.url, altText: 'alert icon' }}
				closeIcon={{ url: closeIcon?.url, altText: 'close icon' }}
				//	backgroundColor={backgroundColor.cssClass}
				backgroundColor={'bg-primary-light'} // TODO: remove once we have the values
				onClose={onBannerClose}
			/>
		)
	);
};
