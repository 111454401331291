import axios from 'axios';

export const optimizelyContentApi = async (url: string): Promise<any> => {
	const element = document.getElementById('app');
	const domainUrl = element?.getAttribute('data-domain') ?? '';
	const contentDeliveryApiUri = element?.getAttribute('data-contentdelivery-url') ?? '';

	if (!domainUrl || !contentDeliveryApiUri) {
		throw new Error('Domain URL or Content Delivery API URL is missing');
	}

	// Remove editor specific parts of URL to render site. To be replaced by preview mode
	const purifiedUrl = url.replace('/EPiServer/CMS/Content', '').replace(/,,[^/]*\//g, '');

	const response = await axios
		.get(
			domainUrl +
				contentDeliveryApiUri +
				`?contentUrl=${encodeURIComponent(purifiedUrl)}&matchExact=true&config=`,
		)
		.then((response) => {
			return response.data;
		});

	return response;
};
