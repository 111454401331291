import React from 'react'; // Ensure this line is present
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { StrictMode } from 'react';

const app = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);
app.render(
	<Router>
		<StrictMode>
			<App />
		</StrictMode>
	</Router>,
);
